@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@layer base {
    body {
        font-family: Inter, "Noto Sans", Roboto, system-ui, sans-serif;
        @apply text-gray-800 dark:text-gray-200;
    }
    
    input, textarea, select {
        @apply border-transparent focus:border-transparent focus:ring-0  #{!important};
    }
    h1 {
        @apply mb-6 text-3xl font-bold text-gray-900 lg:text-4xl dark:text-white;
    }
    h2 {
        @apply mb-4 text-lg font-medium text-gray-900 dark:text-white;
    }
    main {
        @apply py-8 lg:py-16 bg-white dark:bg-gray-800;
    }
    p {
        @apply mb-4 text-gray-600 dark:text-gray-400;
    }
    a {
        @apply font-normal no-underline dark:text-sky-400 hover:underline text-sky-600;
    }
    a.menu-item {
        @apply py-2 text-base font-medium text-gray-900 lg:px-3 lg:py-0 hover:text-sky-600 dark:text-gray-300 dark:hover:text-sky-500 mr-3 inline-block;
    }
    a.footer-item {
        @apply text-base font-medium inline-block m-0 p-0 text-gray-600 hover:text-sky-600 dark:text-gray-400 dark:hover:text-sky-300;
    }
    hr {
        @apply my-12 border-gray-200 dark:border-gray-700;
    }
    section {
        @apply max-w-5xl px-4 mx-auto lg:px-4 pt-6;
    }
    ul {
        @apply pl-4 mb-8 list-disc list-inside text-gray-600 dark:text-gray-400;
    }
    ol {
        @apply pl-4 mb-8 list-decimal list-inside text-gray-600 dark:text-gray-400;
    }
    li {
        @apply mb-2;
    }
    .bi-card {
        @apply m-0 max-w-none bg-white dark:bg-gray-800 shadow rounded-lg p-4 sm:p-6 xl:p-8;
    }

    .dashboard ul {
        @apply list-none m-0 p-0;
    }

    .button-sky {
        @apply flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 focus:outline-none text-white bg-sky-700 border border-transparent enabled:hover:bg-sky-800 focus:ring-sky-300 dark:bg-sky-600 dark:enabled:hover:bg-sky-700 dark:focus:ring-sky-800 rounded-lg focus:ring-2 w-fit #{!important};
    }
    
    .button-sky:disabled {
        @apply opacity-50 cursor-not-allowed #{!important};
    }

    .paggination-sky li[aria-current="page"] button {
        @apply bg-sky-50 text-sky-600 hover:bg-sky-100 hover:text-sky-700 #{!important};
    }


}
